import React, { memo, useEffect, useMemo, useState, VFC } from "react";
import { Fab, Grow, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PushPinIcon from "@mui/icons-material/PushPin";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import UpdatedListModal from "../../molecules/StockMovementDetail/UpdatedListModal";
import PieChartIcon from "@mui/icons-material/PieChart";
import StandardDeviationModel from "../../molecules/StockMovementDetail/StandardDeviationModal";
import PrintIcon from "@mui/icons-material/Print";
import CircularProgress from "@mui/material/CircularProgress";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import SlideInDialog from "../../molecules/SlideInDialog";
import { useApiPost } from "../../../hooks/api/useApiPost";
import {
  CanSortFilterColumns,
  NotSortFilterColumns,
} from "../../pages/StockMovementDetail";
import { Operators } from "../../pages/StockMovementDetail";
import { Typography, Divider } from "@mui/material";

interface Props {
  excelRequest: Paths.PostPrintExcel.Parameters.PrintExcelRequest;
  companyCode: string;
  excelPrintStatus: string;
  excelPrintDate: string;
  header: {
    headerId: number;
    plannerCode: string;
  };
  details: {
    id: number;
    vendor: string;
    item: string;
    is_apply_growth: boolean | undefined;
  }[];
  rowCount: number;
  hasUnfilterableColumn: boolean;
  saveFilter?: () => void;
}

interface fab {
  key: string;
  tooltip: string;
  icon: React.ReactElement;
  onClick: () => void;
  isAction?: boolean;
  isDownLoading?: boolean;
  isAppling?: boolean;
}

const DetailFab: VFC<Props> = memo((props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [openSlideDialog, setOpenSlideDialog] = useState(false);
  const [openSuggestQtyModal, setOpenSuggestQtyModal] = useState(false);
  const [openStdevModal, setOpenStdevModal] = useState(false);
  const [isExpandFab, setIsExpandFab] = useState(false);
  const [isDling, setIsDling] = useState(false);
  const [printExcelConfContent, setPrintExcelConfContent] =
    useState<React.ReactNode>(<></>);

  const postRequestAsync = useApiPost<Paths.PostGrowth.Responses.$200>(
    "print",
    "",
    true
  );

  const handleFab = () => setIsExpandFab((prev) => !prev);
  const handleStdevClose = () => setOpenStdevModal(false);
  const handleClosePrintExcelDialog = () => setOpenSlideDialog(false);
  const handleOK = async () => {
    setIsDling(true);
    setSnackbar({ open: false, message: "" });
    handleClosePrintExcelDialog();

    const body: Paths.PostPrintExcel.Parameters.PrintExcelRequest = {
      headerId: props.header.headerId,
      companyCode: props.companyCode,
      plannerCode: props.header.plannerCode,
      filterType: props.excelRequest.filterType || "AND", // default and
      filterInfo: props.excelRequest.filterInfo,
      sortInfo: props.excelRequest.sortInfo,
    };

    postRequestAsync({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          message: "It may take a few minutes.",
          severity: res.code === 200 ? "success" : "error",
        });
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: "Request is failed.",
          severity: "error",
        });
      });
  };

  useEffect(() => {
    setIsDling(props.excelPrintStatus === "Processing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.excelPrintDate, props.excelPrintStatus]);

  // フィールド名を表示名に変換
  const convertFieldName = (key: string): string => {
    for (const obj of [...CanSortFilterColumns, ...NotSortFilterColumns]) {
      if (obj.hasOwnProperty(key)) {
        return obj[key];
      }
    }
    return key;
  };

  // オペレーターを表示名に変換
  const convertOperator = (key: string): string => {
    for (const obj of Operators) {
      if (obj.hasOwnProperty(key)) {
        return obj[key];
      }
    }
    return key;
  };

  const convertFilterString = (
    request: Paths.PostPrintExcel.Parameters.PrintExcelRequest["filterInfo"],
    filterType?: string
  ): React.ReactElement => {
    if (!request || request.length === 0)
      return <Typography key={"non-filter"}>No filter</Typography>;

    //filterTypeの頭文字を大文字にする+
    const separator = filterType
      ? `${filterType?.charAt(0).toUpperCase() + filterType?.slice(1)} `
      : "And ";

    return (
      <>
        {request.map((r, i) => {
          // デフォルト値
          if (
            request.length === 1 &&
            r.field === "vendor" &&
            r.operator === "contains" &&
            r.value === ""
          )
            return <Typography key={i}>No filter</Typography>;

          const fieldName = convertFieldName(r.field || "");
          const operatorName = convertOperator(r.operator || "");
          const value = r.value !== undefined ? r.value : "";
          return i === 0 ? (
            <Typography
              style={{
                textIndent:
                  i === 0 ? "" : separator === "And " ? "2.1em" : "1.4em",
              }}
              key={i}
            >
              {fieldName} {operatorName} {value}
            </Typography>
          ) : (
            <Typography key={i}>
              {separator} {fieldName} {operatorName} {value}
            </Typography>
          );
        })}
      </>
    );
  };

  const convertSortString = (
    request: Paths.PostPrintExcel.Parameters.PrintExcelRequest["sortInfo"]
  ): React.ReactElement => {
    if (!request || request.length === 0)
      return <Typography key={"non-sort"}>No sort</Typography>;

    return (
      <>
        {request.map((r, i) => {
          const fieldName = convertFieldName(r.field || "");
          const operatorName = convertOperator(r.operator || "");
          return (
            <Typography key={i}>
              {i === 0 ? "" : " , "} {fieldName} {operatorName}
            </Typography>
          );
        })}
      </>
    );
  };

  // Excel Print Content
  useEffect(() => {
    const { filterInfo, sortInfo, filterType } = props.excelRequest;

    const printExcelConfContent = (
      <div style={{ padding: "10px" }}>
        <Typography gutterBottom>
          <strong>Are you sure you want to print Excel?</strong>
        </Typography>
        <Typography>
          <strong>Filter</strong>
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {convertFilterString(filterInfo, filterType)}
        </Typography>
        <Divider style={{ margin: "10px 0" }} />
        <Typography>
          <strong>Sort</strong>
        </Typography>
        <Typography variant="subtitle1">
          {convertSortString(sortInfo)}
        </Typography>
        <Divider style={{ margin: "10px 0" }} />
      </div>
    );

    setPrintExcelConfContent(printExcelConfContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.excelRequest.filterInfo, props.excelRequest.sortInfo]);

  const fabs: fab[] = useMemo(
    () => [
      {
        key: "updated_list",
        tooltip: "Update Log",
        icon: <FactCheckIcon />,
        onClick: () => setOpenSuggestQtyModal(true),
      },
      {
        key: "standard_dev_analysis",
        tooltip: "Standard Deviation",
        icon: <PieChartIcon />,
        onClick: () => setOpenStdevModal(true),
      },
      {
        key: "save_filter",
        tooltip: "Save filter",
        icon: <PushPinIcon />,
        onClick: props.saveFilter ?? (() => console.log("invalid save filter")),
      },
      {
        key: "print_excel",
        tooltip: "Print Excel",
        icon: <PrintIcon />,
        onClick: () => {
          setOpenSlideDialog(true);
        },
        isDownLoading: isDling || props.excelPrintStatus === "Processing",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDling]
  );

  return isExpandFab ? (
    <>
      <UpdatedListModal
        open={openSuggestQtyModal}
        setOpen={setOpenSuggestQtyModal}
        headerID={props.header.headerId}
        details={props.details}
      />
      <StandardDeviationModel
        open={openStdevModal}
        handleClose={handleStdevClose}
        headerID={props.header.headerId}
      />
      <SlideInDialog
        open={openSlideDialog}
        handleClose={handleClosePrintExcelDialog}
        onClickCancel={handleClosePrintExcelDialog}
        //hasUnFileterColmunls=true フィルター不可の項目があればOKを押せない
        onClickOk={props.hasUnfilterableColumn ? undefined : handleOK}
        title="Print STM Excel"
        content={printExcelConfContent}
      />
      {fabs.map((fab, i) => (
        <Grow
          key={fab.key}
          in={isExpandFab}
          style={{ transformOrigin: "0 0 0" }}
          {...(isExpandFab ? { timeout: i * 150 + 150 } : {})}
        >
          <Fab
            sx={{
              opacity: "0.8",
              position: "fixed",
              bottom: `${i * 64 + 96}px`,
              right: "16px",
              zIndex: 200,
            }}
            color={fab.isAction ? "default" : "primary"}
            onClick={fab.onClick}
            disabled={
              fab.key === "print_excel" &&
              (fab.isDownLoading ||
                props.rowCount > 25000 ||
                props.rowCount === 0)
            }
          >
            <Tooltip title={fab.tooltip} arrow={true}>
              {fab.icon}
            </Tooltip>
            {fab.isDownLoading && (
              <CircularProgress
                size={68}
                sx={{
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
            )}
          </Fab>
        </Grow>
      ))}
      <Fab
        color="primary"
        sx={{
          opacity: "0.8",
          position: "fixed",
          bottom: "32px",
          right: "16px",
          zIndex: 200,
        }}
        onClick={handleFab}
      >
        <ExpandMoreIcon />
      </Fab>
    </>
  ) : (
    <Fab
      sx={{
        opacity: "0.4",
        position: "fixed",
        bottom: "32px",
        right: "16px",
        zIndex: 200,
      }}
      onClick={handleFab}
    >
      <ExpandLessIcon color="primary" />
    </Fab>
  );
});

export default DetailFab;
