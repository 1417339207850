import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleClose: () => void;
  title: string;
  content: string | React.ReactNode;
  onClickCancel?: () => void;
  onClickOk?: () => void;
}

const SlideInDialog: React.VFC<Props> = ({
  open,
  handleClose,
  title,
  content,
  onClickCancel,
  onClickOk,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {typeof content === "string" ? (
            <Typography sx={{ whiteSpace: "pre-line" }}>{content}</Typography>
          ) : (
            content
          )}
        </DialogContent>
        <DialogActions>
          {onClickCancel && <Button onClick={onClickCancel}>Cancel</Button>}
          {onClickOk && (
            <Button color="primary" onClick={onClickOk}>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SlideInDialog;
