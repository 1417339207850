import { Fab, Grid, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useApiGetWithCache } from "../../hooks/api/useApiGetWithCache";
import LoadingBackdrop from "../molecules/LoadingBackdrop";
import ItemInformation from "../organisms/ItemDetail/ItemInformation";
import StockChart from "../organisms/ItemDetail/StockChart";
import WhereUsedModels from "../organisms/ItemDetail/WhereUsedModels";
import SaveIcon from "@mui/icons-material/Save";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { FilteredDetailsState } from "../../stores/FiltererDetailsState";
import { useUpdateSuggestQty } from "../../hooks/api/useUpdateSuggestQty";
import SuggestQty from "../organisms/ItemDetail/SuggestQty";
import { useMutateDetail } from "../../hooks/api/mutate/useMutateDetail";
import SimulationModal from "../molecules/ItemDetail/SimulationModal";
import SlideInDialog from "../molecules/SlideInDialog";
import { UserState } from "../../stores/UserState";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import HasUpdateAuthority from "../../functions/HasUpdateAuthority";
import { CalcGrowthRateAdjust } from "../../functions/CalcGrowthRate";
import { CalcRoundByMoq } from "../../functions/CalcRoundByMoq";
import { StmDetailKeysGet } from "../../functions/storage/StmDetailKeys";
import {
  StmUpdatedDetailSet,
  StmUpdatedDetailGet,
  StmUpdatedDetailReset,
} from "../../functions/storage/StmUpdatedDetail";

type Params = {
  stmId: string;
  itemId: string;
};

const ItemDetail = () => {
  const { stmId, itemId } = useParams<Params>();
  let stmIdStr = stmId || "0";
  let itemIdStr = itemId || "0";

  const id = parseInt(itemIdStr);
  const [detail, setDetail] =
    useState<Paths.GetStockMovementDetails.Responses.$200["details"][0]>();
  const [air, setAir] = useState({ qty: 0, dirty: false });
  const [sea, setSea] = useState({ qty: 0, dirty: false });

  const [updatedUsingGrowthRates, setUpdatedUsingGrowthRates] = useState(false);
  const [growthRateDialog, setGrowthRateDialog] = useState({
    open: false,
    growthRate: 1,
  });
  const [modelGrowthRate, setModelGrowthRate] = useState(1);
  const [openSimulationModal, setOpenSimulationModal] = useState(false);
  const [filteredDetails] = useRecoilState(FilteredDetailsState);
  const [user] = useRecoilState(UserState);
  // Routing
  const navigate = useNavigate();
  const stmListPath = `/stockmovement/${stmIdStr}`;
  const moveDetail = () => navigate(stmListPath);

  // Fetcher
  const mutateDetail = useMutateDetail(stmIdStr);
  const { data, loading, error, mutate } =
    useApiGetWithCache<Paths.GetStockMovementDetails.Responses.$200>(
      "stm",
      "stockmovement_details",
      {},
      stmIdStr
    );

  const updateSuggestQtyAsync = useUpdateSuggestQty(stmIdStr);
  const isOpenSlideDialog = useRef(false);
  const keyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (isOpenSlideDialog.current) {
        return;
      }
      const index = filteredDetails.visibleRowId.indexOf(id);
      if (e.key === "ArrowUp" && index > 0) {
        e.preventDefault();
        navigate(
          `${stmListPath}/item/${filteredDetails.visibleRowId[
            index - 1
          ].toString()}`
        );
        return;
      }
      if (
        e.key === "ArrowDown" &&
        index + 1 !== filteredDetails.visibleRowId.length
      ) {
        e.preventDefault();
        navigate(
          `${stmListPath}/item/${filteredDetails.visibleRowId[
            index + 1
          ].toString()}`
        );
        return;
      }
      if (e.shiftKey && e.key === "Enter") {
        moveDetail();
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );
  const hasUpdateAuth = HasUpdateAuthority(
    user?.is_user_update,
    user?.company_code
  );
  const [applyGrowth, setApplyGrowth] = useState(
    detail ? Boolean(detail.is_apply_growth) : false
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const getDetailsMap = (): Map<
    string,
    Paths.GetStockMovementDetails.Responses.$200["details"][0]
  > => {
    const detailsMap = new Map<
      string,
      Paths.GetStockMovementDetails.Responses.$200["details"][0]
    >();
    data?.details.forEach((v) => {
      detailsMap.set(v.item + "Y" + v.vendor, v);
    });
    return detailsMap;
  };
  const [detailsMap] = useState(getDetailsMap());
  const saveSuggestQtyAsync = (
    air: { qty: number; dirty: boolean },
    sea: { qty: number; dirty: boolean },
    detail:
      | Paths.GetStockMovementDetails.Responses.$200["details"][0]
      | undefined
  ) => {
    if (!detail || (!air.dirty && !sea.dirty)) {
      return;
    }
    setIsUpdating(true);
    // LocalStorageに更新内容が残っていたら削除する
    if (StmUpdatedDetailGet()) {
      StmUpdatedDetailReset();
    }
    const body = {
      vendor: detail.vendor,
      item: detail.item,
    } as Paths.UpdateSuggestQty.Parameters.SuggestQty;
    const key = detail.item + "Y" + detail.vendor;
    if (air.dirty) {
      body["air"] = air.qty;
      detailsMap.set(key, {
        ...detail,
        suggest_sea: sea.qty,
        is_apply_growth: applyGrowth,
      });
    }
    if (sea.dirty) {
      body["sea"] = sea.qty;
      body["is_apply_growth"] = applyGrowth;
      body["is_updated_using_growth_rates"] = updatedUsingGrowthRates;
      detailsMap.set(key, {
        ...detail,
        suggest_sea: sea.qty,
        is_apply_growth: applyGrowth,
      });
    }
    // 更新処理に時間がかかる為、最新の内容をLocalStorageに保存しておく
    const updateMap = new Map<
      string,
      Paths.GetStockMovementDetails.Responses.$200["details"][0]
    >();
    updateMap.set(key, detailsMap.get(detail.item + "Y" + detail.vendor)!);
    StmUpdatedDetailSet(JSON.stringify(Array.from(updateMap.entries())));

    const array = [...detailsMap.values()];
    mutate({ ...data!, details: array });
    updateSuggestQtyAsync(body);
    setAir({ ...air, dirty: false });
    setSea({ ...sea, dirty: false });
    mutateDetail();
    setIsUpdating(false);
    return;
  };

  const setGrowthRate = (growthRate: number) =>
    setGrowthRateDialog((p) => ({
      ...p,
      growthRate: CalcGrowthRateAdjust(
        user?.machine_sale_growth?.min ?? 0,
        user?.machine_sale_growth?.max ?? 0,
        growthRate
      ),
    }));
  const handleOpenGrowthRateDialog = () => {
    isOpenSlideDialog.current = true;
    setGrowthRateDialog((p) => ({ ...p, open: true }));
  };
  const handleCloseGrowthRateDialog = () => {
    isOpenSlideDialog.current = false;
    setGrowthRateDialog((p) => ({ ...p, open: false }));
  };

  const handleOkGrowthRateDialog = () => {
    const result = CalcRoundByMoq(
      sea.qty * growthRateDialog.growthRate,
      detail?.moq || 1
    ).toFixed();
    if ((detail?.suggest_sea || 0) === Number(result)) {
      handleCloseGrowthRateDialog();
      return;
    }
    setSea((p) => ({
      dirty: true,
      qty: CalcRoundByMoq(
        p.qty * growthRateDialog.growthRate,
        detail?.moq || 1
      ),
    }));
    setApplyGrowth(true);
    setUpdatedUsingGrowthRates(true);
    handleCloseGrowthRateDialog();
  };

  const getDetailFromStorageOrData = (
    keys: string[],
    id: number,
    updatedDetail: string | null,
    data:
      | { details: Paths.GetStockMovementDetails.Responses.$200["details"] }
      | undefined
  ): Paths.GetStockMovementDetails.Responses.$200["details"][0] | undefined => {
    const key = keys[id - 1];
    if (data === undefined) {
      return;
    }
    if (updatedDetail !== null && updatedDetail?.length > 0) {
      const storageMap = new Map<
        string,
        Paths.GetStockMovementDetails.Responses.$200["details"][0]
      >(JSON.parse(updatedDetail));
      return storageMap.has(key)
        ? storageMap.get(key)
        : data.details.find((f) => f.item + "Y" + f.vendor === key);
    }
    return data.details.find((f) => f.item + "Y" + f.vendor === key);
  };

  useEffect(() => {
    window.addEventListener("keydown", keyDownHandler);
    if (air.dirty || sea.dirty) {
      return () => {
        window.removeEventListener("keydown", keyDownHandler);
      };
    }

    const updatedDetail = StmUpdatedDetailGet();
    // 保存したmutateDetailがあればそれを使い、無ければfindで取得する
    const keys = StmDetailKeysGet();
    const detail = getDetailFromStorageOrData(keys!, id, updatedDetail, data);
    setDetail({ ...(detail as any) });
    setAir({ qty: detail?.suggest_air || 0, dirty: false });
    setSea({ qty: detail?.suggest_sea || 0, dirty: false });
    setApplyGrowth(detail ? Boolean(detail.is_apply_growth) : false);
    setUpdatedUsingGrowthRates(false);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const handleOpen = () => {
    setOpenSimulationModal(true);
  };
  const handleClose = () => {
    setOpenSimulationModal(false);
  };
  // default false
  const isOnlySea = data?.header.only_sea ?? false;
  const getDialogContent = (moq: number) => {
    const result = CalcRoundByMoq(
      sea.qty * growthRateDialog.growthRate,
      moq || 1
    ).toFixed();
    const formulaString = `(${sea.qty} → ${(
      sea.qty * growthRateDialog.growthRate
    ).toFixed()} MOQ: ${moq || "-"})`;
    if ((detail?.suggest_sea || 0) === Number(result)) {
      return `The result is same as the original qty due to MOQ. ${formulaString}`;
    }
    return `Are you sure want to multiple the growth rate ${growthRateDialog.growthRate}? ${formulaString}`;
  };

  return error ? (
    <Typography>Data Not Found</Typography>
  ) : loading || !detail || Object.keys(detail).length === 0 ? (
    <LoadingBackdrop loading={loading} />
  ) : (
    <>
      <Grid container spacing={1} ml={-3}>
        <Grid item xs={12} md={8}>
          <Grid container direction="column">
            <ItemInformation
              detail={detail}
              isOnlySea={isOnlySea}
              growthRate={growthRateDialog.growthRate}
            />
            <Grid borderRadius={2} padding={1} sx={{ boxShadow: 1 }}>
              <SuggestQty
                headerId={stmIdStr}
                id={detail.id}
                vendor={detail.vendor}
                item={detail.item}
                air={air}
                sea={sea}
                setAir={setAir}
                setSea={setSea}
                savedAir={detail.suggest_air || 0}
                savedSea={detail.suggest_sea || 0}
                price={detail.price || 0}
                isOnlySea={isOnlySea}
                isApplyGrowth={detail.is_apply_growth || false}
              />
            </Grid>
            <Grid borderRadius={2} padding={1} sx={{ boxShadow: 1 }}>
              <StockChart
                air={air.qty}
                sea={sea.qty}
                detail={detail}
                monthHeaders={data!.month_headers}
                isOnlySea={isOnlySea}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4} padding={0}>
          {!detail.item || (
            <WhereUsedModels
              headerId={stmIdStr}
              partsNo={detail.item}
              setGrowthRate={setGrowthRate}
              setModelGrowthRate={setModelGrowthRate}
            />
          )}
        </Grid>
      </Grid>
      <SlideInDialog
        open={growthRateDialog.open}
        handleClose={handleCloseGrowthRateDialog}
        title="Apply Growth Rate"
        content={getDialogContent(detail.moq || 1)}
        onClickCancel={handleCloseGrowthRateDialog}
        onClickOk={handleOkGrowthRateDialog}
      />
      <SimulationModal
        air={air.qty}
        sea={sea.qty}
        detail={detail}
        monthHeaders={data!.month_headers}
        open={openSimulationModal}
        handleClose={handleClose}
        handleCloseSave={saveSuggestQtyAsync}
        stmId={stmIdStr}
        itemId={itemIdStr}
        setItemDetailAir={setAir}
        setItemDetailSea={setSea}
        isOnlySea={isOnlySea}
      />
      <Fab
        color="primary"
        aria-label="save"
        sx={{
          position: "fixed",
          bottom: "224px",
          right: "16px",
        }}
        onClick={handleOpen}
      >
        <Tooltip title="Open Simulation" arrow>
          <AppRegistrationIcon />
        </Tooltip>
      </Fab>
      <Fab
        color="primary"
        aria-label="growth-rate"
        sx={{
          position: "fixed",
          bottom: "160px",
          right: "16px",
        }}
        disabled={
          hasUpdateAuth ||
          data?.header.planner_code !== "P" ||
          String(detail.status).includes("D") ||
          (detail.alternate && detail.alternate.includes("[To]")) ||
          growthRateDialog.growthRate === 1 ||
          !growthRateDialog.growthRate || // if Nan
          sea.qty === 0 ||
          modelGrowthRate === 0 ||
          detail.is_apply_growth ||
          applyGrowth
        }
        onClick={handleOpenGrowthRateDialog}
      >
        <Tooltip title="Apply Model's Growth Rate" arrow>
          <SwapVerticalCircleIcon />
        </Tooltip>
      </Fab>

      <Fab
        color="primary"
        aria-label="save"
        sx={{
          position: "fixed",
          bottom: "96px",
          right: "16px",
        }}
        disabled={hasUpdateAuth || !(air.dirty || sea.dirty) || isUpdating}
        onClick={() => saveSuggestQtyAsync(air, sea, detail)}
      >
        <Tooltip title="Save Suggest Qty" arrow>
          <SaveIcon />
        </Tooltip>
      </Fab>
      <Fab
        color="primary"
        aria-label="save"
        sx={{
          position: "fixed",
          bottom: "32px",
          right: "16px",
        }}
        onClick={moveDetail}
      >
        <ArrowBackIcon />
      </Fab>
    </>
  );
};
export default ItemDetail;
