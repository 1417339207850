import { IconButton, Tooltip } from "@mui/material";
import React, { memo, useState, VFC } from "react";
import { useRecoilState } from "recoil";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import { useApiPost } from "../../../hooks/api/useApiPost";
import { SnackbarState } from "../../../stores/SnackbarState";
import SlideInDialog from "../SlideInDialog";
import CentralizedLoading from "../../atoms/CentralizedLoading";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";

interface Props {
  headerId: number;
  companyCode: string;
  plannerCode: string;
  disabled?: boolean;
  status: string;
}

const ApplyGrowthAllButton: VFC<Props> = memo(
  ({ headerId, companyCode, plannerCode, disabled, status }) => {
    const [openModal, setOpenModal] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setSnackbar] = useRecoilState(SnackbarState);
    const postRequestAsync = useApiPost<Paths.PostGrowth.Responses.$200>(
      "growth",
      "",
      true
    );
    const [disable, setdisable] = useState(disabled);

    const handleModalOpen = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      e.stopPropagation();
      setOpenModal(true);
    };
    const handleModalClose = () => {
      setOpenModal(false);
    };
    const updateStatus = (headerId: number, status: "Completed" | "Update") => {
      const body: Paths.PostGrowth.Parameters.UpdateGrowthRequest = {
        headerId: headerId,
        companyCode: companyCode,
        plannerCode: plannerCode,
      };
      if (!(headerId && companyCode && plannerCode)) {
        setSnackbar({
          open: true,
          message:
            "Invalid requests parameter. HeaderID or companyCode or PlannerCode is not found.",
          severity: "error",
        });
      } else {
        postRequestAsync({ body })
          .then((res) => {
            setSnackbar({
              open: true,
              message: "It may take a few minutes",
              severity: res.code === 200 ? "success" : "error",
            });
          })
          .catch(() => {
            setSnackbar({
              open: true,
              message: "Request is failed.",
              severity: "error",
            });
          });
      }
    };

    const onClickConfirmation = () => {
      handleModalClose();
      setdisable(true);
      updateStatus(headerId, "Update");
    };

    return (
      <>
        <SlideInDialog
          open={openModal}
          handleClose={handleModalClose}
          title="Update Sea Suggest Qty"
          content="Are you sure want to apply Model's Growth Rate?"
          onClickCancel={handleModalClose}
          onClickOk={onClickConfirmation}
        />
        {(() => {
          return status === "Processing" ? (
            <CentralizedLoading size={20} />
          ) : status === "Error" || status === "Not Found" ? (
            <IconButton>
              <Tooltip
                title={
                  status === "Error"
                    ? "Unexpected error."
                    : "Updates not found."
                }
                arrow={true}
              >
                <PriorityHighIcon
                  color={status === "Error" ? "error" : "disabled"}
                />
              </Tooltip>
            </IconButton>
          ) : status === "Applied" ? (
            <IconButton>
              <Tooltip
                title="Model's Growth Rate has been applied."
                arrow={true}
              >
                <CheckIcon color={"disabled"} />
              </Tooltip>
            </IconButton>
          ) : (
            <>
              <IconButton disabled={disable} onClick={handleModalOpen}>
                <Tooltip title="Apply Model's Growth Rate" arrow={true}>
                  <SwapVerticalCircleIcon
                    color={disable ? "disabled" : "primary"}
                  />
                </Tooltip>
              </IconButton>
            </>
          );
        })()}
      </>
    );
  }
);

export default ApplyGrowthAllButton;
