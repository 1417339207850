import { Typography } from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
  GridToolbar,
} from "@mui/x-data-grid-pro";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  VFC,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import { useApiPost } from "../../../hooks/api/useApiPost";
import CentralizedLoading from "../../atoms/CentralizedLoading";
import TransitionModal from "../../atoms/TransitionModal";
import UserGrid from "../../atoms/UserGrid";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import HasUpdateAuthority from "../../../functions/HasUpdateAuthority";
import { CustomGridStringOperators } from "../../atoms/CustomDataGridOperators";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  headerID: number;
  details: {
    id: number;
    vendor: string;
    item: string;
    is_apply_growth: boolean | undefined;
  }[];
}
const UpdatedListModal: VFC<Props> = memo((props) => {
  const [rows, setRows] = useState<
    Paths.GetUpdatedSuggestQtyList.Responses.$200["suggest_qty_list"]
  >([]);
  const { data, loading, error, url } =
    useApiGetWithCache<Paths.GetUpdatedSuggestQtyList.Responses.$200>(
      "stm",
      "suggest_qty",
      {},
      String(props.headerID)
    );
  const postRequestAsync =
    useApiPost<Paths.UpdateSuggestQtyRemark.Responses.$200>(
      "suggest_qty_transaction"
    );
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const [user] = useRecoilState(UserState);
  useEffect(() => {
    mutate(url);
    // add row id
    var ls = data?.suggest_qty_list || [];
    for (let index = 0; index < ls.length; index++) {
      ls[index].row_id = index;
    }

    setRows(ls);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, props.open]);

  const numberFormat = (num: number) => {
    var v = num || 0;
    return Number(v.toFixed(1)).toLocaleString();
  };

  const handleRowDoubleClick = (
    vendor: string,
    item: string,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>
  ) => {
    event.preventDefault();
    const detail = props.details.find(
      (f) => f.vendor === vendor && f.item === item
    );
    // If target item is not found, nothing is processed.
    if (!detail) {
      return;
    }
    navigate(`/stockmovement/${props.headerID}/item/${detail.id}`);
  };
  const handleEditCommit = useCallback(
    (params: any, original: any) => {
      if (params["remark"] === original["remark"]) {
        return params;
      }
      const body: Paths.UpdateSuggestQtyRemark.Parameters.UpdateRemark = {
        suggest_qty_id: params["suggest_qty_id"],
        remark: params["remark"],
      };
      postRequestAsync({ body });
      mutate(url);
      return params;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.headerID]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "suggest_qty_id",
        headerName: "suggest_qty_id",
      },
      {
        field: "vendor",
        headerName: "Vendor",
        width: 90,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "item",
        headerName: "Item",
        width: 150,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "type",
        headerName: "Type",
        headerAlign: "center",
        align: "center",
        width: 70,
      },
      {
        field: "quantity",
        headerName: "Quantity",
        headerAlign: "center",
        align: "center",
        width: 180,
        valueGetter: (value, row) =>
          `${
            row.before_qty !== -1 ? numberFormat(row.before_qty) : "auto"
          } --> ${row.after_qty !== -1 ? numberFormat(row.after_qty) : "auto"}`,
      },
      {
        field: "remark",
        headerName: "Remark",
        editable: !HasUpdateAuthority(user?.is_user_update, user?.company_code),
        width: 390,
        filterOperators: CustomGridStringOperators,
      },
      {
        field: "is_apply_growth",
        headerName: "G.Rate",
        headerAlign: "center",
        align: "center",
        valueGetter: (value, row) => (row.is_apply_growth ? "Y" : ""),
        width: 70,
      },
      {
        field: "updated",
        headerName: "Updated",
        headerAlign: "right",
        align: "right",
        sortable: false,
        valueGetter: (value, row) => row.create,
        renderCell: (params: GridRenderCellParams) => (
          <UserGrid
            user={params.value.user}
            date={params.value.date}
            size={19}
            fontsize="14px"
          />
        ),
        minWidth: 180,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleClose = () => props.setOpen(false);
  if (error) {
    props.setOpen(false);
    return <Typography>Error</Typography>;
  }
  return (
    <TransitionModal
      open={props.open}
      handleClose={handleClose}
      title={"Update Log"}
      maxWidth={"lg"}
    >
      {loading || !data ? (
        <CentralizedLoading />
      ) : (
        <>
          <DataGridPro
            key={props.headerID}
            rows={rows}
            rowHeight={30}
            columns={columns}
            hideFooter
            slots={{
              toolbar: GridToolbar,
            }}
            density="compact"
            getRowId={(row) => row.row_id}
            onCellDoubleClick={(params, event) => {
              if (params.field !== "remark") {
                handleRowDoubleClick(params.row.vendor, params.row.item, event);
              }
            }}
            processRowUpdate={(updatedRow, originalRow) =>
              handleEditCommit(updatedRow, originalRow)
            }
            initialState={{
              columns: {
                columnVisibilityModel: {
                  suggest_qty_id: false,
                },
              },
            }}
          />
        </>
      )}
    </TransitionModal>
  );
});

export default UpdatedListModal;
