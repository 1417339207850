// compornet for error message
import { Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const ErrorMessage = () => {
  return (
    <>
      <Typography fontSize={20}>
        An error has occurred. The error is likely to be due to the invalid
        value of MasterData or discrepancy of Planner Code.
      </Typography>
      <Typography fontSize={20}>
        Please confirm whether the MasterData has invalid values or which
        Planner Code you have selected...
      </Typography>
      <br />
      <Typography fontSize={18}>
        If you have any questions, please contact{" "}
        <i>
          <u>latinamerica@mj.makita.co.jp</u>
        </i>
        <MailOutlineIcon />
      </Typography>
    </>
  );
};

export default ErrorMessage;
