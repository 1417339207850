import {
  Grid,
  Typography,
  IconButton,
  List,
  ListItemText,
  Box,
  ListItem,
  Divider,
  ListItemAvatar,
} from "@mui/material";
import { memo, VFC, useCallback, useState, useEffect } from "react";
import DownloadWithPresignUrl from "../../../functions/DownloadWithPresignUrl";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useApiGetAsync } from "../../../hooks/api/useApiGetAsync";
import { LinearProgressWithLabel } from "../../atoms/LinearProgressWithLabel";
import UserAvatar from "../../molecules/UserAvatar";

interface Props {
  id: string;
  update_at: string;
  status: string;
  progressPercent: number;
  header_no: string;
  s3Key: string;
  user_sub: string;
  user_name: string;
}

const suffix = ".xlsx";
const extractFileName = (file: string): string => {
  // xxxx/yyyyy/zzz.xlsx　→　zzz.xlsx
  const pathSegments = file.split("/");
  return pathSegments[pathSegments.length - 1];
};

const DownloadSTMExcel: VFC<Props> = memo((props) => {
  const [, setSnackbar] = useRecoilState(SnackbarState);
  const [loading, setLoading] = useState(false);
  const [updateAt, setUpdateAt] = useState(props.update_at);
  const getAsync = useApiGetAsync<Paths.GetCreatedSTMExcel.Responses.$200>(
    "stm",
    "create_stm_excel"
  );

  const onClickDownload = useCallback(async () => {
    setLoading(true);
    const currentDate = new Date().toLocaleDateString();
    getAsync({}, props.id)
      .then((v) => {
        DownloadWithPresignUrl(
          v.presigned_url,
          `${currentDate}_${props.header_no}${suffix}`
        );
      })
      .then(() => {
        setSnackbar({
          open: true,
          message: "Download Success!",
          severity: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.status === "Success") {
      if (updateAt !== props.update_at) {
        onClickDownload();
      } else {
        setUpdateAt(props.update_at);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status, props.update_at]);

  return (
    <Grid container direction="column">
      <Typography fontWeight="bold">Issued Printed Excel (Latest)</Typography>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.default",
          position: "relative",
          overflow: "auto",
          maxHeight: 210,
          maxWidth: 360,
          padding: 0,
          "& ul": { padding: 0 },
        }}
      >
        {props.status === "" || props.status === "Waiting" ? null : (
          <Box>
            <ListItem
              alignItems="flex-start"
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="download"
                  disabled={props.status !== "Success" || loading}
                  onClick={onClickDownload}
                  color="primary"
                >
                  <CloudDownloadIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <UserAvatar
                  userSub={props.user_sub}
                  userName={props.user_name}
                  size={35}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    {props.status === "Processing" ? (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgressWithLabel
                          value={props.progressPercent}
                        />
                      </Box>
                    ) : (
                      <Typography noWrap>
                        {extractFileName(
                          props.status === "Error"
                            ? "Timed out due to data size" // Error message
                            : props.status === "Success"
                            ? props.s3Key
                            : props.status
                        )}
                      </Typography>
                    )}
                  </Grid>
                }
                secondary={
                  props.update_at && props.status === "Success" ? (
                    <Typography variant="caption" display="block" color="gray">
                      {new Date(props.update_at).toLocaleDateString(
                        navigator.language,
                        {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )}
                    </Typography>
                  ) : null
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </Box>
        )}
      </List>
    </Grid>
  );
});

export default DownloadSTMExcel;
