// style情報をExport
import { Theme } from "@mui/material/styles";

export const DetailDataGridSX = {
  // Cell color setting
  "& .back-order.positive": {
    backgroundColor: "rgb(255, 187, 184) !important",
  },
  "& .item-no": {
    fontWeight: "bold",
    fontSize: 18,
  },
  "& .suggest-air": {
    backgroundColor: "rgb(219, 222, 245) !important",
    fontWeight: "bold",
    fontSize: 18,
    "&: hover": {
      backgroundColor: "rgb(219, 222, 245) !important",
    },
  },
  "& .no-moq-suggest-air": {
    backgroundColor: "rgb(255, 187, 184) !important",
    fontWeight: "bold",
    fontSize: 18,
  },
  "& .suggest-sea": {
    backgroundColor: "rgb(224, 243, 219) !important",
    fontWeight: "bold",
    fontSize: 18,
    "&: hover": {
      backgroundColor: "rgb(224, 243, 219) !important",
    },
  },
  "& .no-moq-suggest-sea": {
    backgroundColor: "rgb(255, 187, 184) !important",
    fontWeight: "bold",
    fontSize: 18,
  },
  "& .not-layer-qty": {
    backgroundColor: "rgba(255,165,0,0.3) !important",
    fontWeight: "bold",
    fontSize: 18,
  },
  "& .flag.a": {
    backgroundColor: "rgba(242, 30, 30, 0.2)",
    fontWeight: "bold",
    color: "#FAFAFA",
    fontSize: 18,
  },
  "& .flag.b": {
    backgroundColor: "rgba(247, 23, 132, 0.2)",
    fontWeight: "bold",
    color: "#FAFAFA",
    fontSize: 18,
  },
  "& .flag.c": {
    backgroundColor: "rgba(122, 113, 214, 0.2)",
    fontWeight: "bold",
    color: "#FAFAFA",
    fontSize: 18,
  },
  "& .flag.d": {
    backgroundColor: "rgba(0, 134, 202, 0.2)",
    fontWeight: "bold",
    color: "#FAFAFA",
    fontSize: 18,
  },
  "& .flag.e": {
    backgroundColor: "rgba(0, 140, 162, 0.2)",
    fontWeight: "bold",
    color: "#FAFAFA",
    fontSize: 18,
  },
  // Row color setting
  "& .default-row": {
    borderColor: "rgba(224,224,224,0)",
  },
  "& .checked-row": {
    borderLeftStyle: "solid",
    borderColor: "teal",
    borderLeftWidth: "1px",
  },
  "& .discon-row": {
    backgroundColor: "rgb(130, 130, 130)",
    borderColor: "rgb(130, 130, 130)",
    "&: hover": {
      backgroundColor: "rgb(130, 130, 130) !important",
    },
    "& .MuiDataGrid-cell--pinnedLeft,.MuiDataGrid-cell--pinnedRight ": {
      backgroundColor: "rgb(130, 130, 130) !important",
      "&: hover": {
        backgroundColor: "rgb(130, 130, 130) !important",
      },
    },
    "& .suggest-air ": {
      backgroundColor: "rgb(113, 117, 140) !important",
    },
    "& .suggest-sea ": {
      backgroundColor: "rgb(120, 140, 116) !important",
    },
  },
  "& .discon-row.Mui-selected": {
    backgroundColor: "rgb(130, 130, 130)!important",
    borderColor: "rgb(130, 130, 130)",
  },
  "& .MuiDataGrid-cell": {
    alignItems: "center",
    display: "flex",
  },
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  "& .Mui-error": {
    backgroundColor: (theme: Theme) =>
      `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: (theme: Theme) =>
      theme.palette.mode === "dark" ? "#ff4343" : "#750f0f",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontSize: 13,
    "& .small": {
      fontSize: 9,
    },
  },
  "& .MuiDataGrid-footerContainer": {
    minHeight: 20,
  },
};
