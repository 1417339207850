import AlternateMaintenance from "../components/pages/AlternateMaintenance";
import CompanyMaintenance from "../components/pages/CompanyMaintenance";
import CreateStockMovement from "../components/pages/CreateStockMovement";
import ItemDetail from "../components/pages/ItemDetail";
import StockMovementDetails from "../components/pages/StockMovementDetail";
import StockMovementList from "../components/pages/StockMovementList";
import UserMaintenance from "../components/pages/UserMaintenance";
import VendorMaintenance from "../components/pages/VendorMaintenance";
import WeightMaintenance from "../components/pages/WeightMaintenance";
import ParameterMaintenance from "../components/pages/ParameterMaintenance";

export const privateRoutes = [
  {
    path: "",
    children: <StockMovementList />,
  },
  {
    path: "create",
    children: <CreateStockMovement />,
  },
  {
    path: "stockmovement/:id",
    children: <StockMovementDetails />,
  },
  {
    path: "stockmovement/:stmId/item/:itemId",
    children: <ItemDetail />,
  },
  {
    path: "user_maintenance",
    children: <UserMaintenance />,
  },
  {
    path: "company_maintenance",
    children: <CompanyMaintenance />,
  },
  {
    path: "alternate_maintenance",
    children: <AlternateMaintenance />,
  },
  {
    path: "vendor_maintenance",
    children: <VendorMaintenance />,
  },
  {
    path: "weight_maintenance",
    children: <WeightMaintenance />,
  },
  {
    path: "parameter_maintenance",
    children: <ParameterMaintenance />,
  },
];
