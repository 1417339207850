/* eslint-disable */
// DEV環境インフラ情報
const awsmobile = {
  env: "DEV",
  aws_project_region: "sa-east-1",
  aws_cloud_logic_custom: [
    {
      name: "apie413e623",
      endpoint: "https://tlmswut34h.execute-api.sa-east-1.amazonaws.com/dev",
      region: "sa-east-1",
    },
  ],
  aws_cognito_identity_pool_id:
    "sa-east-1:37834d9c-f429-419a-96f4-3824f4e894e1",
  aws_cognito_region: "sa-east-1",
  aws_user_pools_id: "sa-east-1_LQ67Xxjrm",
  aws_user_pools_web_client_id: "35cq68faivomceanp6sgf0rpog",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: ["TOTP"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_user_files_s3_bucket: "stm-md-dev",
  aws_user_files_s3_bucket_region: "sa-east-1",
};

export default awsmobile;
