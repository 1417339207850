import { Box, CircularProgress, Link, Typography } from "@mui/material";
import React, { memo, useEffect, useMemo, useState, VFC } from "react";
import { useApiGetWithCache } from "../../../hooks/api/useApiGetWithCache";
import {
  DataGridPro,
  GridColDef,
  GridRowParams,
  GridRenderCellParams,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnMenuItemProps,
  useGridApiContext,
  GridSortModel,
  GridSortDirection,
  GridPinnedRowsProp,
} from "@mui/x-data-grid-pro";
import DrawingModal from "../../molecules/DrawingModal";
import { DrawingModel } from "../../../models/drawing_model";
import { useRecoilState } from "recoil";
import { UserState } from "../../../stores/UserState";
import { EngineeringChangeModel } from "../../../models/engineering_change_model";
import EngineeringChangeDialog from "../../molecules/ItemDetail/EngineeringChangeDialog";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import SortIcon from "@mui/icons-material/Sort";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CalcGrowthRate } from "../../../functions/CalcGrowthRate";
import LineChartCell from "../../molecules/LineChartCell";

interface Props {
  headerId: string;
  partsNo: string;
  setGrowthRate: (growthRate: number) => void;
  setModelGrowthRate: (growthRate: number) => void;
}

type row = Paths.GetPartsWhereUsed.Responses.$200["models"][0] & {
  sales: number[];
};

const WhereUsedModels: VFC<Props> = memo((props) => {
  const [rows, setRows] = useState<row[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [drawingModel, setDrawingModel] = useState<DrawingModel>();
  const [ecDialogOpen, setEcDialogOpen] = useState(false);
  const [ecModel, setEcModel] = useState<EngineeringChangeModel>({
    no: "",
    path: "",
  });
  const [user] = useRecoilState(UserState);
  // Have to get region from database!
  const queryParameters: Paths.GetPartsWhereUsed.QueryParameters = {
    region: (user?.regions || []).join("y"), // y is param splitter
    locale: "en-US",
  };
  const { data, loading, error } =
    useApiGetWithCache<Paths.GetPartsWhereUsed.Responses.$200>(
      "makmate",
      "parts",
      { queryStringParameters: queryParameters },
      props.partsNo
    );
  const { data: models } =
    useApiGetWithCache<Paths.GetModelSales.Responses.$200>(
      "stm",
      "models",
      {
        queryStringParameters: {
          models: Array.from(
            // be unique
            new Set(data?.models.map((m) => m.model_no))
          ).join("y"),
        },
      },
      `${props.headerId}/${props.partsNo}`,
      data !== undefined && data.models.length > 0
    );
  const { data: ec } =
    useApiGetWithCache<Paths.GetEngineeringChanges.Responses.$200>(
      "makmate",
      "engineering_change",
      {
        queryStringParameters: {
          region: (user?.regions ?? []).join("y"), // y is param splitter),
          primary_region: user?.primary_region ?? "",
          locale: "en-US",
          models: Array.from(
            // be unique
            new Set(data?.models.map((m) => m.model_no))
          ).join("y"),
        },
      },
      props.partsNo,
      data !== undefined && data.models.length > 0
    );

  useEffect(() => {
    const totalModelSales = models?.models
      ?.find((f) => f.model_no === "TOTAL")
      ?.sales.map((m) => m.qty);

    let growthRate = CalcGrowthRate(totalModelSales);

    growthRate = Math.round(growthRate * 10) / 10;

    props.setGrowthRate(growthRate);
    props.setModelGrowthRate(growthRate);
    const dataWithTotal = [
      {
        id: -1,
        model_no: "TOTAL",
        name: "",
      } as Paths.GetPartsWhereUsed.Responses.$200["models"][0],
      ...(data?.models || []),
    ];
    setRows(mergeArray(dataWithTotal, models, ec));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, models, ec, props.partsNo]);

  const handleRowClick = (row: GridRowParams["row"]) => {
    if (row.model_no === "TOTAL") {
      return;
    }
    setDrawingModel({
      model: row.nameplate,
      specCode: row.spec_code,
      parts: props.partsNo,
      region: row.regions,
    });
    setDialogOpen(true);
    return;
  };
  const handleEngineeringChangeClick = (no: string, path: string) => {
    setEcModel({ no: no, path: path });
    setEcDialogOpen(true);
  };

  const rowHeight = 100;
  const headerHeight = 56;
  const renderSalesHistoryChart = (
    params: GridRenderCellParams<
      any,
      Paths.GetModelSales.Responses.$200["models"][0]
    >
  ) => {
    if (!params.value) {
      if (params.row.model_no === "TOTAL") {
        return <Typography>-</Typography>;
      } else {
        return (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress color="primary" />
          </Box>
        );
      }
    }

    if (params.row.sales.length === 0) {
      return <Typography>-</Typography>;
    }
    const month = params.row.sales.map((v: { month: string; qty: number }) => {
      return v.month;
    });
    const qty = params.row.sales.map((v: { month: string; qty: number }) => {
      return v.qty;
    });

    return <LineChartCell month={month} qty={qty} />;
  };

  const renderEngineeringChanges = (
    params: GridRenderCellParams<
      any,
      Paths.GetEngineeringChanges.Responses.$200["engineering_changes"][0]
    >
  ) => {
    if (params.row.model_no === "TOTAL") {
      return "";
    }
    if (!params.value) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (params.row.documents.length === 0) {
      return <Typography>-</Typography>;
    }
    return (
      <>
        {params.row.documents.map((v: any, i: any) => (
          <React.Fragment key={v.no}>
            {i !== 0 && <Typography>, </Typography>}
            <Link
              fontSize={12}
              component="button"
              variant="body2"
              onClick={() => {
                handleEngineeringChangeClick(v.no, v.path);
              }}
            >
              {v.no}
            </Link>
          </React.Fragment>
        ))}
      </>
    );
  };
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "model_no",
        headerName: "Model",
        width: 85,
      },
      {
        field: "nameplate",
        headerName: "Nameplate",
      },
      {
        field: "spec_code",
        headerName: "Spec",
        align: "center",
        width: 60,
      },
      {
        field: "sales",
        headerName: "Sales",
        renderCell: renderSalesHistoryChart,
        minWidth: 150,
        width: 180,
        align: "left",
        sortable: false,
      },
      {
        field: "documents",
        headerName: "E/C",
        renderCell: renderEngineeringChanges,
        align: "left",
        width: 80,
      },
      {
        field: "growth_rate",
        headerName: "G.Rate",
        align: "center",
        headerAlign: "center",
        type: "number",
        width: 80,
        valueGetter: (value, row): string => {
          var rate = 0;
          if (row.sales) {
            rate =
              CalcGrowthRate(
                row.sales.map((v: any, i: any) => v.qty) as number[]
              ) || 0;
          }
          return rate === 0 ? "" : rate.toFixed(1);
        },
      },
      {
        field: "name",
        headerName: "Description",
        minWidth: 180,
      },
      {
        field: "display_ids",
        headerName: "ID",
        valueGetter: (value) => (value as string[])?.join(","),
        width: 50,
      },
      {
        field: "regions",
        headerName: "Region",
        width: 70,
      },
      {
        field: "voltage",
        headerName: "Volt",
        align: "center",
        minWidth: 60,
        width: 60,
      },
      {
        field: "total_sales_3M",
        headerName: "3M SalesAv",
        align: "right",
        width: 20,
        type: "number",
        valueGetter: (value, row): string =>
          row.sales &&
          CalcTotal(
            (row.sales.map((v: any, i: any) => v.qty) as number[]).slice(9)
          ),
      },
      {
        field: "total_sales_6M",
        headerName: "6M SalesAv",
        align: "right",
        width: 20,
        type: "number",
        valueGetter: (value, row): string =>
          row.sales &&
          CalcTotal(
            (row.sales.map((v: any, i: any) => v.qty) as number[]).slice(6)
          ),
      },
      {
        field: "total_sales_12M",
        headerName: "12M SalesAv",
        align: "right",
        width: 20,
        type: "number",
        valueGetter: (value, row): string =>
          row.sales &&
          CalcTotal(row.sales.map((v: any, i: any) => v.qty) as number[]),
      },
      {
        field: "qty",
        headerName: "Used Qty",
        align: "right",
        width: 20,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (user?.regions.length === 0) {
    return <Typography>User Region is not defined.</Typography>;
  }
  if (error) {
    return <Typography>Error</Typography>;
  }

  const pinnedRows: GridPinnedRowsProp =
    rows.length > 0
      ? {
          top: [rows[0]],
        }
      : {};

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="primary" />
    </Box>
  ) : !data ? (
    <Typography>Data is not found</Typography>
  ) : (
    <Box
      mr={-6}
      sx={{
        "& .MuiDataGrid-cell": {
          alignItems: "center",
          display: "flex",
        },
        "& .total-row": {
          bgcolor: "rgba(12,171,168,0.2)",
          "&:hover": {
            bgcolor: "rgba(12,171,168,0.2)",
          },
        },
      }}
      height="100%"
    >
      <DataGridPro
        style={{ fontSize: 11 }}
        key={props.partsNo}
        rows={rows}
        columns={columns}
        hideFooter={true}
        onRowDoubleClick={(params: GridRowParams, event) =>
          handleRowClick(params.row)
        }
        rowHeight={rowHeight}
        getRowClassName={(params) =>
          params.row.model_no === "TOTAL" ? "total-row" : ""
        }
        sx={{ overflowY: "auto", maxHeight: headerHeight + rowHeight * 6 }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              nameplate: false,
              spec_code: false,
              qty: false,
              total_sales_3M: false,
              total_sales_6M: false,
              total_sales_12M: false,
            },
          },
          sorting: {
            sortModel: [{ field: "total_sales_12M", sort: "desc" }],
          },
        }}
        slots={{ columnMenu: CustomColumnMenu }}
        pinnedRows={pinnedRows}
      />
      {drawingModel ? (
        <DrawingModal
          isOpen={dialogOpen}
          setOpen={setDialogOpen}
          drawingModel={drawingModel!}
          primaryRegion={user?.primary_region ?? ""}
        />
      ) : (
        <></>
      )}
      {ecModel.no ? (
        <EngineeringChangeDialog
          isOpen={ecDialogOpen}
          setOpen={setEcDialogOpen}
          no={ecModel.no}
          path={ecModel.path}
        />
      ) : (
        <></>
      )}
    </Box>
  );
});

const mergeArray = (
  arr1: Paths.GetPartsWhereUsed.Responses.$200["models"] | undefined,
  arr2: Paths.GetModelSales.Responses.$200 | undefined,
  arr3: Paths.GetEngineeringChanges.Responses.$200 | undefined
): row[] => {
  const map = new Map();
  arr1?.forEach((item) => map.set(item.model_no, item));
  arr2?.models.forEach((item) =>
    map.set(item.model_no, { ...map.get(item.model_no), ...item })
  );
  arr3?.engineering_changes.forEach((item) =>
    map.set(item.model_no, { ...map.get(item.model_no), ...item })
  );
  return Array.from(map.values());
};

const CalcTotal = (nums: number[] | undefined): string => {
  if (!nums?.length) {
    return "";
  }
  return Number(!nums ? "0" : nums.reduce((acc, cur) => acc + cur))
    .toFixed(1)
    .toLocaleString();
};

const getSortModel = (
  fieldName: string,
  direction: GridSortDirection
): GridSortModel => [{ field: fieldName, sort: direction }];

const CustomSortItem = (props: GridColumnMenuItemProps) => {
  const { month } = props;
  const apiRef = useGridApiContext();
  const sortFiledName = "Sort Total" + month + "M";
  const handleClick = (direction: GridSortDirection) => {
    apiRef.current.setSortModel(
      getSortModel("total_sales_" + month + "M", direction)
    );
    apiRef.current.applySorting();
  };

  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  return (
    <List sx={{ width: "100%" }} component="nav">
      <ListItemButton onClick={handleMenuClick}>
        <ListItemIcon>
          <SortIcon />
        </ListItemIcon>
        <ListItemText>{sortFiledName}</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} onClick={() => handleClick("asc")}>
            <ListItemIcon>
              <ArrowUpwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="ASC" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => handleClick("desc")}>
            <ListItemIcon>
              <ArrowDownwardIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="DESC" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  const itemProps = {
    colDef: props.colDef,
  };
  if (itemProps.colDef.field === "sales") {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenu3M: CustomSortItem,
          columnMenu6M: CustomSortItem,
          columnMenu12M: CustomSortItem,
          columnMenuSortItem: null,
        }}
        slotProps={{
          columnMenu3M: {
            displayOrder: 1,
            month: "3",
          },
          columnMenu6M: {
            displayOrder: 2,
            month: "6",
          },
          columnMenu12M: {
            displayOrder: 3,
            month: "12",
          },
        }}
      />
    );
  } else {
    return <GridColumnMenu {...props} />;
  }
};
export default WhereUsedModels;
