import { LocalStorageGet } from "./LocalStorageGet";
import { LocalStorageSet } from "./LocalStorageSet";
import { LocalStorageDelete } from "./LocalStorageDelete";

const key = "web-stm-updated-detail-storage213vfv2";

export const StmUpdatedDetailSet = (keys: string) => {
  LocalStorageSet({ key: key, obj: keys });
};

export const StmUpdatedDetailGet = () => {
  return LocalStorageGet<string>(key);
};

export const StmUpdatedDetailReset = () => {
  LocalStorageDelete(key);
};
