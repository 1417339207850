import React, { VFC } from "react";
import {
  AmplifyProvider,
  View,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router/Router";
import { RecoilRoot } from "recoil";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { CssBaseline } from "@mui/material";
import { ReactComponent as Logo } from "./svg/makita_logo.svg";

LicenseInfo.setLicenseKey(
  "be52b9c6aec13f03ab7f91735367e526Tz0xMDEyNTUsRT0xNzYyMzk3NjY1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI="
);
interface AppProps {
  env: "DEV" | "PRD";
}

const theme = (env: string) => {
  return createTheme({
    palette: {
      background: {
        default: "#FAFAFA",
      },
      primary: {
        main: env === "DEV" ? "#000000" : "#008CA2",
      },
    },
  });
};

const App: VFC<AppProps> = ({ env }) => {
  return (
    <ThemeProvider theme={theme(env)}>
      <CssBaseline />
      <AmplifyProvider>
        <RecoilRoot>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </RecoilRoot>
      </AmplifyProvider>
    </ThemeProvider>
  );
};

const components = {
  SignIn: {
    Header() {
      return (
        <View textAlign="center">
          <Logo />
        </View>
      );
    },
  },
};

export default withAuthenticator(App, { components });
