import {
  DataGridPro,
  GridToolbar,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
} from "@mui/x-data-grid-pro";
import { memo, useCallback, useMemo, useState, VFC } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApiDelete } from "../../../hooks/api/useApiDelete";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { SnackbarState } from "../../../stores/SnackbarState";
import { mutate } from "swr";
import { CustomGridStringOperators } from "../../atoms/CustomDataGridOperators";

interface Props {
  vendors?: Paths.GetVendors.Responses.$200["vendors"];
  onClickRow: (sub: string) => void;
}
const VendorList: VFC<Props> = memo(({ vendors, onClickRow }) => {
  const [open, setOpen] = useState(false);
  const [deleteVendorNo, setDeleteVendorNo] = useState("");
  const rows: GridRowsProp = useMemo(() => vendors || [], [vendors]);
  const deleteVendor = useApiDelete<
    Paths.DeleteVendor.Responses.$200 | Paths.DeleteVendor.Responses.Default
  >("vendor");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSnackbar] = useRecoilState(SnackbarState);
  const DeleteRow = (vendor: string) => {
    setOpen(true);
    const body: Paths.DeleteVendor.Parameters.DeleteRequest = {
      vendor: vendor,
    };
    deleteVendor({ body })
      .then((res) => {
        setSnackbar({
          open: true,
          severity: res.code === 200 ? "success" : "error",
          message: `${vendor} is deleted.`,
        });
        mutate("/stm/vendor");
      })
      .finally(() => {
        setDeleteVendorNo("");
        mutate("/stm/vendor");
      });

    setOpen(false);
  };
  const handleOpen = (vendor: string) => {
    setDeleteVendorNo(vendor);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const renderDelete = (params: GridRenderCellParams) => {
    return (
      <IconButton color="primary" onClick={() => handleOpen(params.row.vendor)}>
        <DeleteIcon />
      </IconButton>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "vendor",
      headerName: "Vendor",
      width: 200,
      filterOperators: CustomGridStringOperators,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      filterOperators: CustomGridStringOperators,
    },
    {
      field: "delete",
      headerName: "",
      align: "center",
      renderCell: renderDelete,
    },
  ];
  const handleRowClick = useCallback((id: GridRowParams["id"]) => {
    onClickRow(id.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        style={{ height: "100%", width: "100%" }}
        sx={{
          "& .MuiDataGrid-cell": {
            display: "flex",
          },
        }}
      >
        <DataGridPro
          rows={rows}
          rowHeight={30}
          columns={columns}
          hideFooter={true}
          getRowId={(row) => row.vendor}
          slots={{
            toolbar: GridToolbar,
          }}
          density="compact"
          onRowClick={(params: GridRowParams) => handleRowClick(params.id)}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Vendor</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure want to delete {deleteVendorNo}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={() => DeleteRow(deleteVendorNo)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default VendorList;
