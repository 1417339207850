import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridRowParams,
  GridRowsProp,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import React, { memo, useCallback, useState, useMemo, FC } from "react";
import DeleteAlternateDialog from "../../molecules/AlternateMaintenance/DeleteAlternateDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingBackdrop from "../../molecules/LoadingBackdrop";
import { CustomGridStringOperators } from "../../atoms/CustomDataGridOperators";

interface Props {
  alternates?: Paths.GetAlternates.Responses.$200["alternates"];
  loading: boolean;
  onClickRow: (id: number) => void;
  handleRefresh: () => void;
}
interface dialog {
  open: boolean;
  alternate: {
    alternate_id: number;
    fromItem: string;
    toItem: string;
  };
}
const AlternateTable: FC<Props> = memo((props) => {
  const rows: GridRowsProp = useMemo(
    () => props.alternates || [],
    [props.alternates]
  );
  const [deleteDialog, setDeleteDialog] = useState<dialog>({
    open: false,
    alternate: { alternate_id: 0, fromItem: "", toItem: "" },
  });
  const handleDelete = (dialog: dialog) => {
    setDeleteDialog(dialog);
  };
  const handleClose = () =>
    setDeleteDialog((prev) => {
      const next = { ...prev };
      next.open = false;
      return next;
    });

  const columns: GridColDef[] = [
    {
      field: "from_item",
      headerName: "From Item",
      width: 150,
      filterOperators: CustomGridStringOperators,
    },
    {
      field: "from_qty",
      headerName: "From Qty",
      width: 90,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "to_item",
      headerName: "To Item",
      width: 150,
      filterOperators: CustomGridStringOperators,
    },
    {
      field: "to_qty",
      headerName: "To Qty",
      width: 90,
      headerAlign: "center",
      align: "center",
      type: "number",
    },
    {
      field: "created",
      headerName: "Created",
      valueGetter: (value): string =>
        new Date(value).toLocaleDateString(navigator.language, {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      width: 160,
      headerAlign: "right",
      align: "right",
      disableExport: true,
    },
    {
      field: "actions",
      type: "actions",
      width: 40,
      disableExport: true,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() =>
            handleDelete({
              open: true,
              alternate: {
                alternate_id: params.row.id,
                fromItem: params.row.from_item,
                toItem: params.row.to_item,
              },
            })
          }
        />,
      ],
    },
  ];
  const handleRowClick = useCallback((row: GridRowParams["row"]) => {
    props.onClickRow(row.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return props.loading ? (
    <LoadingBackdrop loading={props.loading} />
  ) : (
    <>
      <DeleteAlternateDialog
        open={deleteDialog.open}
        handleClose={handleClose}
        alternate={deleteDialog.alternate}
        handleRefresh={props.handleRefresh}
      />
      <DataGridPro
        rows={rows}
        rowHeight={30}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
        density="compact"
        onRowClick={(params: GridRowParams) => handleRowClick(params)}
      />
    </>
  );
});

export default AlternateTable;
